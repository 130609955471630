import classes from './style/SettingForm.module.scss';
import Input from '../../../components/base/Input';
import BaseButton from '../../../components/base/BaseButton';
import SelectInput from '../../../components/base/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import { RepoFactory } from '../../../baseRepository/Factory';
import Toast from '../../../components/base/Toast';
import { AxiosError, AxiosResponse } from 'axios';
import { errorActions } from '../../../store/error-slice';
import MultiSelectInput from '../../../components/base/MultiSelectInput';
import { useTranslation } from '../../../providers/locale-provider';
import MultiSelectDatePicker from '../../../components/base/MultiSelectDatePicker';
import Loading from '../../../components/base/Loading';
import { useEffect, useState } from 'react';
import { Option } from '../../../types/sharedTypes';
import { CALENDAR_TYPE, WEEK_DAY_TYPE, WEEK_DAYS } from '../types/type';
import { RootState } from '../../../store';
import SelectTime from '../../../components/base/SelectTime';
const settingRepository = () => RepoFactory.get('setting');
const calendarTypes = [CALENDAR_TYPE['gregorian']];
const weekly_days = [
  WEEK_DAY_TYPE[WEEK_DAYS.Sunday],
  WEEK_DAY_TYPE[WEEK_DAYS.Monday],
  WEEK_DAY_TYPE[WEEK_DAYS.Tuesday],
  WEEK_DAY_TYPE[WEEK_DAYS.Wednesday],
  WEEK_DAY_TYPE[WEEK_DAYS.Thursday],
  WEEK_DAY_TYPE[WEEK_DAYS.Friday],
  WEEK_DAY_TYPE[WEEK_DAYS.Saturday],
];
const defaultFlexTime: string = '0';
const defaultTimeDuration: string = '8';
const SettingForm = () => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const defaultCompanyId = useSelector((state: RootState) => state.auth.userInfo.user.defaultCompanyId);
  const [companyName, setCompanyName] = useState<string>('');
  const [companyAddress, setCompanyAddress] = useState<string>('');
  const [calendarType, setCalendarType] = useState(calendarTypes[0]);
  const [weeklyOff, setWeeklyOff] = useState<Option[]>();
  const [holidays, setHolidays] = useState<string[]>();
  const [calendarOutHolidays, setCalendarOutHolidays] = useState<string[]>([]);
  const [startTime, setStartTime] = useState<string>();
  const [flextime, setFlextime] = useState<string>(defaultFlexTime);
  const [timeDuration, setTimeDuration] = useState<string>(defaultTimeDuration);
  const timeValidation = (value: string): string => {
    let reg = /\b(0?[1-9]|1[0-9]|2[0-4])\b/g;
    if (reg.test(value)) {
      return value;
    } else if (value === '') {
      return '';
    } else {
      return timeDuration;
    }
  };
  const weeklyOffHandler = (weekly_off_days: string[]): Option[] => {
    let array: Option[] = [];
    weekly_off_days.map((item) => {
      return array.push(WEEK_DAY_TYPE[item]);
    });
    return array;
  };
  const weeklyOffSetter = (selectedItem: Option[]) => setWeeklyOff([...selectedItem]);

  const holidaysHandler = (selectedItem: string) => {
    let dateArray = selectedItem.split(',');
    let convertedDate: string[] = [];
    dateArray.forEach((data) => {
      convertedDate.push(data.replaceAll('/', '-'));
    });
    setCalendarOutHolidays(convertedDate);
  };

  const getSetting = () => {
    setLoading(true);
    settingRepository()
      .getSetting(defaultCompanyId)
      .then(({ data: { company, holidays } }: AxiosResponse) => {
        setCalendarType(CALENDAR_TYPE[company.calender_type]);
        setWeeklyOff(weeklyOffHandler(company.weekly_off_days));
        setStartTime(company.start_time);
        setTimeDuration(String(company.duration / 3600000));
        setHolidays(holidays);
        setFlextime(company.flextime);
        setCompanyName(company.name);
        setCompanyAddress(company.address);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const updateSetting = async () => {
    setLoading(true);
    let weekly_off_days: number[] = [];
    weeklyOff?.forEach((off) => {
      weekly_off_days.push(Number(off.value));
    });
    let body = {
      name: companyName,
      address: companyAddress,
      calender_type: calendarType.title,
      duration: Number(timeDuration) * 3600000, //mili second
      weekly_off_days: weekly_off_days,
      start_time: startTime, // hh:mm
      flextime: flextime,
      holidays: calendarOutHolidays,
    };
    settingRepository()
      .updateSetting(defaultCompanyId, body)
      .then((result: AxiosResponse<any>) => {
        setShowToast(true);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSetting();
  }, []);
  return (
    <>
      <Loading loading={loading} />
      {showToast && <Toast message={t9n.setting_successful_update} onClose={() => setShowToast(false)} />}
      <div className={classes.formContainer}>
        <div className={classes.settingLayer}>
          <span className={classes.settingTitle}>{t9n.calendar_setting_text}</span>
          <div className={classes.actionBox}>
            <div className={classes.actionItems}>
              <SelectInput
                label={t9n.calendar_type_text}
                placeHolder="Select Calendar Type"
                defaultValue={calendarType}
                options={calendarTypes}
              />
            </div>
            <div className={classes.actionItems}>
              <MultiSelectInput
                selectedOptions={weeklyOffSetter}
                defaultOptions={weeklyOff}
                label={t9n.weekend_text}
                placeHolder="Select Off Days "
                options={weekly_days}
                disabledSearchIcon={true}
              />
            </div>
            <div className={classes.actionItems}>
              <MultiSelectDatePicker
                title={t9n.public_holidays}
                defaultValues={holidays}
                selectedValues={holidaysHandler}
              />
            </div>
          </div>
        </div>
        <div className={classes.settingLayer}>
          <span className={classes.settingTitle}>{t9n.work_time_stting_text}</span>
          <div className={classes.actionBox}>
            <div className={classes.actionItems}>
              <div className={classes.timePeriod}>
                <span className={classes.timeText}>{t9n.start_from_text}</span>
                <div className={classes.timeBox}>
                  <div className={classes.selectTime}>
                    <span>{t9n.work_time_text}</span>
                    <SelectTime fromDate={startTime} setDate={setStartTime} />
                  </div>
                  <div className={classes.timeTelorance}>
                    <span>+</span>
                    <span>-</span>
                  </div>
                  <Input
                    size="lg"
                    type="number"
                    name="flextime"
                    title={t9n.flextime_text}
                    min={0}
                    max={23}
                    value={flextime}
                    handleChange={(e) => setFlextime(timeValidation(e.target.value))}
                  />
                </div>
              </div>
            </div>
            <div className={classes.actionItems}>
              <Input
                size="lg"
                title={t9n.duration_text}
                name="workTimeDuration"
                placeholder={t9n.working_hours_placeholder}
                value={timeDuration}
                handleChange={(e) => {
                  setTimeDuration(timeValidation(e.target.value));
                }}
                tail="h"
              />
            </div>
          </div>
        </div>
        <div className={classes.actionButton}>
          <BaseButton
            size="sm"
            title={t9n.save_btn_text}
            color="primary"
            hasRightIcon={false}
            hasLeftIcon={false}
            onClickHandler={(e) => updateSetting()}
          />
        </div>
      </div>
    </>
  );
};
export default SettingForm;
