import classes from './style/punchOut.module.scss';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ClockIcon from '../../../../assets/image/Icons/clockIcon.svg';

import Loading from '../../../../components/base/Loading';
import BaseButton from '../../../../components/base/BaseButton';
import Modal from '../../../../components/base/Modal';
import Input from '../../../../components/base/Input';
import TextArea from '../../../../components/base/TextArea';
import { RepoFactory } from '../../../../baseRepository/Factory';
import { AxiosError, AxiosResponse } from 'axios';
import { errorActions } from '../../../../store/error-slice';
import { diffTime, different, timeToSeconds, convertDate } from '../../../../core/helpers/FormatDate';
import { isDefined, getTimeDifference } from '../../../../core/helpers/utils';
import Toast from '../../../../components/base/Toast';
import { useTranslation } from '../../../../providers/locale-provider';
import { setStatus, setPunchId } from '../../../../store/attendanceSlice';
import { UserStatus } from '../../../Auth/types/type';
import { RepoType, PunchType, Optional } from '../../../../types/sharedTypes';
import moment from 'moment';
import Checkbox from '../../../../components/base/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DatePicker, { DatePickerPosition, DatePickerSize } from '../../../../components/base/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

const timeSheetRepository = () => RepoFactory.get(RepoType.Attendance);
const profileRepository = () => RepoFactory.get(RepoType.Profile);

interface props {
  punchId: number | null;
  handlePunchOut?: (status: boolean) => void;
  loadnewData?: (state: boolean) => void;
  startTime: number;
}
interface IProject {
  projectId: number;
  projectName: string;
  active: boolean;
  duration: {
    hour: number | string;
    minute: number | string;
  };

  description?: string;
  requireDescription?: boolean;
}
interface IDetail {
  projectId: number;
  duration: number;
  description?: string;
}

interface IAttendance {
  punch_in_time: Optional<Dayjs>;
  punch_in_date: Optional<Date>;
  punch_out_time: Optional<Dayjs>;
  punch_out_date: Optional<Date>;
}

const minTime = 119;

const PunchOut = (props: props) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [punchOutModal, setPunchOutModal] = useState(false);
  const [duration, setDuration] = useState<string>('');
  const [projects, setProjects] = useState<IProject[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [optionalProject, setOptionalProject] = useState(false);
  const [optionalProjectFlag, setOptionalProjectFlag] = useState<boolean>(false);
  const [projectCheckbox, setProjectCheckbox] = useState<boolean>(false);
  const [showOptionalProject, setShowOptionalProject] = useState<boolean>(false);
  const [currentDateTimeEpoch, setCurrentDateTimeEpoch] = useState<number>(0);
  const [lastCheckIn, setLastCheckIn] = useState<number>(0);
  const [punchInVisibility, setPunchInVisibility] = useState(false);
  const [punchOutVisibility, setPunchOutVisibility] = useState(false);
  const [attendance, setAttendance] = useState<IAttendance>({
    punch_in_time: null,
    punch_in_date: null,
    punch_out_time: null,
    punch_out_date: null,
  });
  const [firstAttendance, setFirstAttendance] = useState<IAttendance>({
    punch_in_time: null,
    punch_in_date: null,
    punch_out_time: null,
    punch_out_date: null,
  });

  let currentDate = Math.floor(Date.now() / 1000);

  const handleCheckboxChange = () => {
    setOptionalProjectFlag(true);
    setProjectCheckbox(!projectCheckbox);
  };

  const toggleDatePicker = (picker: 'in' | 'out') => {
    if (picker === 'in') {
      setPunchInVisibility(true);
      setPunchOutVisibility(false);
    } else {
      setPunchOutVisibility(true);
      setPunchInVisibility(false);
    }
  };

  const resetProjects = () => {
    projects &&
      setProjects(
        projects.map((project) => ({
          ...project,
          active: false,
          duration: { hour: '', minute: '' },
          description: '',
        }))
      );
  };

  useEffect(() => {
    setDuration(getAttendanceDuration() || '');
  }, [attendance]);

  const openPunchOutModal = async () => {
    resetProjects();
    let startTime = props.startTime * 1000;
    let endTime = new Date().getTime();

    setLoading(true);
    await timeSheetRepository()
      .userStatus()
      .then((res: AxiosResponse<any>) => {
        setCurrentDateTimeEpoch(res.data.result.currentDateTimeEpoch);
        setLastCheckIn(res.data.result.last_check_in_datetime);
        endTime = res.data.result.currentDateTimeEpoch * 1000;
        startTime = res.data.result.last_check_in_datetime * 1000;
        setLoading(false);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          setLoading(false);
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });

    const moment1 = moment(startTime);
    const moment2 = moment(endTime);
    const duration = moment.duration(moment2.diff(moment1)).asSeconds();

    const diffTime = different(startTime, endTime, 'hour')(t9n);
    setDuration(String(diffTime));

    setAttendance({
      ...attendance,
      punch_in_date: new Date(startTime),
      punch_out_date: new Date(endTime),
      punch_in_time: dayjs(new Date(startTime)),
      punch_out_time: dayjs(new Date(endTime)),
    });
    setFirstAttendance({
      ...firstAttendance,
      punch_in_date: new Date(startTime),
      punch_out_date: new Date(endTime),
      punch_in_time: dayjs(new Date(startTime)),
      punch_out_time: dayjs(new Date(endTime)),
    });

    let initAttendance = {
      punch_in_date: new Date(startTime),
      punch_out_date: new Date(endTime),
      punch_in_time: dayjs(new Date(startTime)),
      punch_out_time: dayjs(new Date(endTime)),
    };

    const handlePunchout = () => {
      setPunchOutModal(false);
      timeSheetRepository()
        .punchOut(props.punchId)
        .then(() => {
          setLoading(false);
          if (props?.loadnewData) props.loadnewData(true);
          dispatch(setStatus(UserStatus.Out));
          dispatch(setPunchId(null));
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            dispatch(errorActions.setHasError(true));
            dispatch(errorActions.setError(error.response?.data));
            setLoading(false);
          }
        });
    };

    if (duration > minTime) {
      if (projects.length > 0) {
        if (projects.length > 1) {
          setPunchOutModal(true);
          return;
        }
        if (projects[0].requireDescription || optionalProject) {
          setPunchOutModal(true);
        } else {
          savePunchOut(true, initAttendance);
        }
      } else {
        setPunchOutModal(false);
        savePunchOut(true, initAttendance);
      }
    } else {
      handlePunchout();
    }
  };

  const exitModal = () => setPunchOutModal(false);
  const getProjects = () => {
    setProjects([]);
    setLoading(true);
    timeSheetRepository()
      .projects()
      .then((result: AxiosResponse) => {
        setLoading(false);
        const data = result.data.result.data;
        const projects: [] = result.data.result.projectDescriptions;
        let allProjects: IProject[] = [];
        if (data.length > 0) {
          projects.forEach((project: any) => {
            allProjects.push({
              active: false,
              projectId: project?.project?.id,
              projectName: project?.project?.name,
              duration: { hour: '', minute: '' },
              description: '',
              requireDescription: !!project?.description,
            });
          });
          setProjects(allProjects);
        }
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
          setLoading(false);
        }
      });
  };

  const getProfile = () => {
    setLoading(true);

    profileRepository()
      .getProfile()
      .then((res: AxiosResponse<any>) => {
        const user = res.data.result;
        setLoading(false);
        setOptionalProject(user.optional_punchout_modal);
        if (projects.length === 1 && !projects[0].requireDescription) {
          setShowOptionalProject(true);
        }
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const handleOptionalProject = () => {
    var bodyFormData = new FormData();
    optionalProjectFlag && bodyFormData.append('optional_punchout_modal', (!projectCheckbox).toString());
    const updatedProfile = bodyFormData;
    setLoading(true);

    profileRepository()
      .editProfile(updatedProfile)
      .then((res: AxiosResponse<any>) => {
        profileRepository()
          .getProfile()
          .then((res: AxiosResponse<any>) => {
            const user = res.data.result;
          });
        setLoading(false);
      })
      .catch((error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const combinedDateTime = (date: Date, time: Dayjs) => {
    // this method generate a new date based on the provided date and time
    const year = date?.getFullYear();
    const month = date?.getMonth();
    const day = date?.getDate();

    const hour = time.hour();
    const minute = time.minute();
    const second = time.second();
    const millisecond = time.millisecond();

    return new Date(year, month, day, hour, minute, second, millisecond);
  };

  const getAttendanceDuration = () => {
    const { punch_in_time, punch_out_time, punch_in_date, punch_out_date } = attendance;

    if (
      !isDefined(punch_in_time) ||
      !isDefined(punch_out_time) ||
      !isDefined(punch_in_date) ||
      !isDefined(punch_out_date)
    ) {
      return;
    }

    const startDate = combinedDateTime(punch_in_date, punch_in_time);
    const endDate = combinedDateTime(punch_out_date, punch_out_time);

    return getTimeDifference({ start: startDate, end: endDate });
  };

  const hoursDuration = (e: React.FormEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id;
    const value = e.currentTarget.value;

    const cloneData: IProject[] = [...projects];
    for (let i = 0; i < cloneData.length; i++) {
      if (id === String(cloneData[i].projectId)) {
        cloneData[i].duration.hour = value;
      }
    }
    setProjects(cloneData);
  };
  const minutesDuration = (e: React.FormEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id;
    const value = e.currentTarget.value;

    const cloneData: IProject[] = [...projects];
    for (let i = 0; i < cloneData.length; i++) {
      if (id === String(cloneData[i].projectId)) {
        cloneData[i].duration.minute = value;
        break;
      }
    }
    setProjects(cloneData);
  };
  const handleDescription = (e: React.FormEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id;
    const value = e.currentTarget.value;
    const cloneData: IProject[] = [...projects];
    for (let i = 0; i < cloneData.length; i++) {
      if (id === String(cloneData[i].projectId)) {
        cloneData[i].description = value;
        break;
      }
    }
    setProjects(cloneData);
  };
  const handleActiveProject = (e: React.FormEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id;
    const cloneData: IProject[] = [...projects];
    for (let i = 0; i < cloneData.length; i++) {
      if (id === String(cloneData[i].projectId)) {
        cloneData[i].active = !cloneData[i].active;

        break;
      }
    }
    setProjects(cloneData);
  };
  const areAttendancesEqual = (attendance1: any, attendance2: any) => {
    return (
      attendance1.punch_in_date.getTime() === attendance2.punch_in_date.getTime() &&
      attendance1.punch_out_date.getTime() === attendance2.punch_out_date.getTime() &&
      attendance1.punch_in_time.$d.getTime() === attendance2.punch_in_time.$d.getTime() &&
      attendance1.punch_out_time.$d.getTime() === attendance2.punch_out_time.$d.getTime()
    );
  };
  const handleToastClose = () => setShowToast(false);
  const savePunchOut = (isAutoPunch: boolean, initAttendance?: any) => {
    let isEdited = isAutoPunch ? false : !areAttendancesEqual(attendance, firstAttendance);
    let errorMessage: Optional<string> = null;
    const { punch_in_time, punch_out_time, punch_in_date, punch_out_date } = attendance;
    let punchIn;
    let punchOut;
    if (!isAutoPunch) {
      if (
        !isDefined(punch_in_time) ||
        !isDefined(punch_out_time) ||
        !isDefined(punch_in_date) ||
        !isDefined(punch_out_date)
      ) {
        errorMessage = 'Please enter all required data!';
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            state: 'error',
            message: errorMessage,
          })
        );
        return;
      }
      punchIn = combinedDateTime(punch_in_date, punch_in_time).getTime() / 1000;
      punchOut = combinedDateTime(punch_out_date, punch_out_time).getTime() / 1000;
    } else {
      punchIn = combinedDateTime(initAttendance.punch_in_date, initAttendance.punch_in_time).getTime() / 1000;
      punchOut = combinedDateTime(initAttendance.punch_out_date, initAttendance.punch_out_time).getTime() / 1000;
    }

    setLoading(true);

    let details: IDetail[] = [];
    if (projects?.length > 0) {
      if (punchOutModal) {
        for (let i = 0; i < projects.length; i++) {
          if (projects[i].active) {
            details.push({
              projectId: projects[i].projectId,
              duration: timeToSeconds(Number(projects[i].duration.hour), Number(projects[i].duration.minute)),
              description: projects[i].description,
            });
          }
        }
        if (details.length > 0) {
          let error = false;
          details.forEach((data) => {
            if (data.duration === 0) {
              error = true;
            }
          });
          if (error) {
            setLoading(false);
            let data = {
              error: 'Bad Request',
              message: 'The format or duration value is wrong',
              statusCode: 400,
            };
            dispatch(errorActions.setHasError(true));
            dispatch(errorActions.setError(data));
          } else {
            const data = {
              punchOut: punchOut,
              punchIn: punchIn,
              punchType: isEdited ? 'manual' : '',
              detail: details,
            };

            timeSheetRepository()
              .punchOut(props.punchId, data)
              .then(() => {
                setPunchOutModal(false);
                setLoading(false);
                if (props.loadnewData) props.loadnewData(true);
                exitModal();
                dispatch(setStatus(UserStatus.Out));
                dispatch(setPunchId(null));
              })
              .catch((error: unknown) => {
                if (error instanceof AxiosError) {
                  dispatch(errorActions.setHasError(true));
                  dispatch(errorActions.setError(error.response?.data));
                  setLoading(false);
                }
              });
          }
        } else {
          setLoading(false);
          let data = {
            error: 'Bad Request',
            message: 'You must select at least one project.',
            statusCode: 400,
          };
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(data));
        }
      } else {
        setPunchOutModal(false);
        const startTime = punchIn;
        const endTime = punchOut;
        const dur = diffTime(startTime, endTime);
        let details: IDetail[] = [];
        const proj = projects[0];
        details.push({
          projectId: proj.projectId,
          duration: timeToSeconds(Number(dur.hours), Number(dur.minutes)),
          description: proj.description,
        });
        const data = {
          detail: details,
        };

        timeSheetRepository()
          .punchOut(props.punchId, data)
          .then(() => {
            setLoading(false);
            if (props.loadnewData) props.loadnewData(true);
            dispatch(setStatus(UserStatus.Out));
            dispatch(setPunchId(null));
          })
          .catch((error: unknown) => {
            if (error instanceof AxiosError) {
              dispatch(errorActions.setHasError(true));
              dispatch(errorActions.setError(error.response?.data));
              setLoading(false);
            }
          });
      }
    } else {
      setPunchOutModal(false);
      timeSheetRepository()
        .punchOut(props.punchId)
        .then(() => {
          setLoading(false);
          if (props?.loadnewData) props.loadnewData(true);
          dispatch(setStatus(UserStatus.Out));
          dispatch(setPunchId(null));
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            dispatch(errorActions.setHasError(true));
            dispatch(errorActions.setError(error.response?.data));
            setLoading(false);
          }
        });
    }
    if (optionalProjectFlag) {
      handleOptionalProject();
    }
  };

  const handleDatePickerChange = (date: Optional<Date>, type: PunchType.PUNCHIN | PunchType.PUNCHOUT) => {
    if (!date) return;
    let hasError = false;
    let punchOut: Date = new Date();
    const now: Date = new Date();
    if (attendance.punch_out_time) {
      punchOut.setTime(attendance.punch_out_time?.valueOf());
      punchOut.setDate(date?.getDate());
      punchOut.setMonth(date?.getMonth());
      punchOut.setFullYear(date?.getFullYear());
    }
    let errorMessage = '';

    if (type === PunchType.PUNCHIN && isDefined(attendance.punch_out_date) && date > attendance.punch_out_date) {
      errorMessage = 'The punch-in date should be earlier than the punch-out.';
    }

    if (type === PunchType.PUNCHOUT && isDefined(attendance.punch_in_date) && punchOut < attendance.punch_in_date) {
      errorMessage = 'The punch-out date needs to be later than the punch-in.';
    }

    if (type === PunchType.PUNCHOUT && punchOut > now) {
      errorMessage = 'The punch-out time cannot be later than the current time.';
      hasError = true;
    }

    if (errorMessage) {
      dispatch(errorActions.setHasError(true));
      dispatch(errorActions.setError({ state: 'error', message: errorMessage }));
    }

    if (hasError) {
      return;
    }

    setAttendance({
      ...attendance,
      [type === PunchType.PUNCHIN ? 'punch_in_date' : 'punch_out_date']: date,
    });
  };

  const handleTimePickerChange = (time: Optional<Dayjs>, type: PunchType.PUNCHIN | PunchType.PUNCHOUT) => {
    if (!time) return;
    setAttendance({
      ...attendance,
      [type === PunchType.PUNCHIN ? 'punch_in_time' : 'punch_out_time']: time,
    });
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    getProfile();
  }, [punchOutModal]);

  return (
    <>
      <Loading loading={loading} />
      {showToast && <Toast message={toastMessage} onClose={handleToastClose} />}
      <BaseButton
        title={t9n.punch_out_btn_text}
        hasLeftIcon={true}
        icon={<img src={ClockIcon} alt="clockIcon" />}
        onClickHandler={openPunchOutModal}></BaseButton>
      {punchOutModal && (
        <Modal
          header={t9n.punch_out_btn_text}
          body={
            <section className="w-100">
              <p className={classes.duration}>
                {t9n.work_duration_text} : {duration}
              </p>
              <div className={`d-flex flex-column gap-3 mb-4`}>
                <div className={`d-flex flex-column gap-2`}>
                  <span>{t9n.punch_in_btn_text}</span>
                  <div className={`d-flex flex-row gap-2 align-items-center`}>
                    <div className={`d-flex w-50`}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          slotProps={{
                            actionBar: {
                              actions: ['cancel', 'accept'],
                            },
                            textField: {
                              inputProps: {
                                readOnly: true, // Prevent user from typing in the input field
                              },
                            },
                          }}
                          name={UserStatus.In}
                          ampm={false}
                          timeSteps={{
                            hours: 1,
                            minutes: 1,
                          }}
                          disableFuture={
                            convertDate('YYYY MM DD', Number(attendance.punch_in_date)) ===
                              convertDate('YYYY MM DD', new Date().getTime()) ||
                            (convertDate('YYYY MM DD', currentDate) ===
                              convertDate('YYYY MM DD', new Date().getTime()) &&
                              convertDate('YYYY MM DD', Number(attendance.punch_in_date)) ===
                                convertDate('YYYY MM DD', new Date().getTime()))
                              ? true
                              : false
                          }
                          closeOnSelect={false}
                          selectedSections="all"
                          value={attendance.punch_in_time}
                          onAccept={(value) => {
                            if (!value) {
                              return;
                            }
                            handleTimePickerChange(value, PunchType.PUNCHIN);
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className={`w-50 align-middle mt-1 position-relative`}>
                      <DatePicker
                        handleSelectedDate={(date) => handleDatePickerChange(date, PunchType.PUNCHIN)}
                        selectedDate={attendance.punch_in_date as Date}
                        defaultDate={attendance.punch_out_date as Date}
                        datePickerPosition={DatePickerPosition.LEFT_Center}
                        datePickerSize={DatePickerSize.MEDIUM}
                        disabled={new Date()}
                        isVisible={punchInVisibility}
                        onClick={() => toggleDatePicker('in')}
                      />
                    </div>
                  </div>
                </div>
                <div className={`d-flex flex-column gap-2`}>
                  <span>{t9n.punch_out_btn_text}</span>
                  <div className={`d-flex flex-row gap-2 align-items-center`}>
                    <div className={`d-flex w-50 `}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          slotProps={{
                            actionBar: {
                              actions: ['cancel', 'accept'],
                            },
                            textField: {
                              inputProps: {
                                readOnly: true, // Prevent user from typing in the input field
                              },
                            },
                          }}
                          name={UserStatus.Out}
                          ampm={false}
                          timeSteps={{
                            hours: 1,
                            minutes: 1,
                          }}
                          closeOnSelect={false}
                          disableFuture={
                            convertDate('YYYY MM DD', Number(attendance.punch_out_date)) ===
                              convertDate('YYYY MM DD', new Date().getTime()) ||
                            (convertDate('YYYY MM DD', currentDate) ===
                              convertDate('YYYY MM DD', new Date().getTime()) &&
                              convertDate('YYYY MM DD', Number(attendance.punch_out_date)) ===
                                convertDate('YYYY MM DD', new Date().getTime()))
                              ? true
                              : false
                          }
                          selectedSections="all"
                          value={attendance.punch_out_time}
                          onAccept={(value) => {
                            if (!value) {
                              return;
                            }
                            handleTimePickerChange(value, PunchType.PUNCHOUT);
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className={`w-50 align-middle mt-1 position-relative`}>
                      <DatePicker
                        handleSelectedDate={(date) => handleDatePickerChange(date, PunchType.PUNCHOUT)}
                        selectedDate={attendance.punch_out_date as Date}
                        defaultDate={attendance.punch_out_date as Date}
                        datePickerPosition={DatePickerPosition.LEFT_Center}
                        datePickerSize={DatePickerSize.MEDIUM}
                        disabled={new Date()}
                        isVisible={punchOutVisibility}
                        onClick={() => toggleDatePicker('out')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {projects.map((project: IProject, index: number) => (
                <div className={`${classes.card} mb-3`} key={index}>
                  <div className="w-100 d-flex align-items-center mb-2">
                    <label className="container-checkbox ">
                      <input
                        type="checkbox"
                        checked={project.active || false}
                        id={String(project.projectId)}
                        key={project.projectId}
                        onChange={handleActiveProject}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <span className={`mx-1 ${classes.label}`}>{project.projectName}</span>
                  </div>
                  <div className="w-100 mt-2">
                    <label htmlFor="">{t9n.duration_text}</label>
                  </div>
                  <div className="mb-3 d-flex flex-wrap ">
                    <div className={`w-50 pe-2 pt-2`}>
                      <Input
                        type="number"
                        name="hours"
                        min={0}
                        max={100000}
                        disable={!project.active}
                        id={String(project.projectId)}
                        value={project.duration.hour}
                        centerText={true}
                        placeholder="h"
                        handleChange={hoursDuration}
                      />
                    </div>
                    <div className={`w-50 px-2 pt-2`}>
                      <Input
                        disable={!project.active}
                        id={String(project.projectId)}
                        type="number"
                        name="minutes"
                        min={0}
                        max={59}
                        centerText={true}
                        value={project.duration.minute}
                        handleChange={minutesDuration}
                        placeholder="m"
                      />
                    </div>
                  </div>

                  <div className="w-100 mb-3"></div>
                  <TextArea
                    title={t9n.leave_description_text}
                    required={project.requireDescription}
                    name="description"
                    disable={!project.active}
                    rows={4}
                    value={project.description}
                    id={String(project.projectId)}
                    handleChange={handleDescription}
                    placeholder={t9n.leave_description_placeholder_text}
                  />
                </div>
              ))}
              {showOptionalProject && (
                <div className={classes.optionalProject}>
                  <Checkbox onChange={handleCheckboxChange} checked={projectCheckbox} />
                  <div
                    title="Filling the working hours of the project is not mandatory for you.&#10;You can revert this change anytime you want in the settings page."
                    className={classes.optionalProjectTxt}>
                    Don't show me again.{' '}
                  </div>
                </div>
              )}
            </section>
          }
          exitAction={exitModal}
          action={
            <div className="w-100">
              <div className="w-100">
                <BaseButton
                  title={t9n.save_btn_text}
                  hasBlock={true}
                  size="lg"
                  onClickHandler={() => savePunchOut(false)}></BaseButton>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};
export default PunchOut;
