export interface META_Data {
  break: string;
  date: string;
  duration: string;
  leaveDuration: string;
  onShiftValue: string;
  offShiftValue: string;
  projectDuration: {
    duration?: string;
    name?: string;
  };
}

export enum IShiftType {
  ON = 'ON',
  OFF = 'OFF',
}

export enum TABS {
  MONTHLY_REPORT = 'monthly_report',
  // PROJECTS_REPORT = 'projects_report',
  MANUAL_REPORT = 'manual_report',
}

type Attendance = {
  id: number;
  durationSplit: boolean;
  checkIn: number | string;
  checkOut: string;
  longEvent: boolean;
  day: string;
  isOffDay: boolean;
  isHoliday: boolean;
  attendanceDur: string;
  edited: number;
  break: string;
  projects: {
    attendanceId: number;
    projectId: number;
    description: string;
    duration: string;
    project: {
      id: number;
      name: string;
      status: boolean;
      description: string;
      createdAt: string;
      deletedAt: string | null;
    };
  }[];
};

export type Data = {
  user: {
    id: number;
    name: string;
    last_name: string;
    username: string;
  };
  project: any | null;
  daily_working_hour: number;
  allAttendanceDays: number;
  allMorethan5HourAttendance: number;
  approvedLeaves: number;
  remainingLeaves: number;
  formattedTotalAttendance: string;
  formattedTotalBreaks: string;
  underTime: number;
  overTime: string;
  totalBreaks: number;
  totalAttendance: number;
  totalOnShift: number;
  totalOffShift: number;
  formattedTotalOnShift: string;
  formattedTotalOffShift: string;
  mandatory_working_hours_month: string;
  projectDuration: {
    id: number;
    duration: number;
    name: string;
    formattedDuration: string;
  }[];
  allAtten: Attendance[];
  metadata: {
    date: string;
    duration: string;
    onShiftValue: string;
    offShiftValue: string;
    break: string;
    leaveDuration: string;
    projectDuration: {
      name: string;
      duration: string;
    };
  }[];
};
