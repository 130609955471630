import { META_Data, IShiftType } from '../types/index';
import {
  getDateValue,
  getTimeValue,
  getBreakTime,
  getShiftTime,
  getProjectDurationTime,
  convertEpochToTime,
} from './reportUtils';

export const advancedRecords = (
  attendance: any,
  metaData: META_Data[],
  isFirstAttendanceOfMonth: boolean,
  projectId?: string,
  isFirstAttendanceOfDay?: boolean
) => {
  const firstAttendanceOfMonthDurationSplit = isFirstAttendanceOfMonth && attendance.durationSplit ? '** ' : '';
  const lastAttendanceOfMonthDurationSplit = !isFirstAttendanceOfMonth && attendance.durationSplit ? '** ' : '';
  const isAttendance = attendance.isAttendance;
  return projectId
    ? {
        Name: firstAttendanceOfMonthDurationSplit + getDateValue(attendance.checkIn),
        'Project name': lastAttendanceOfMonthDurationSplit + getDateValue(attendance.checkOut),
        'Start Date':
          attendance.checkOut === 'N/A' || !isFirstAttendanceOfDay
            ? ''
            : getBreakTime(attendance.day, metaData)?.leaveTime,
        'End Date': attendance.isHoliday ? '*' : '',
        'Total Working Time On Project': attendance.isOffDay ? '*' : '',
        'Total Number of Working Days': attendance.longEvent ? (attendance.durationSplit ? 'Duration Split' : '*') : '',
        '': isFirstAttendanceOfDay ? getProjectDurationTime(attendance.day, metaData) : '',
        ' ': attendance.projects.length === 0 ? '' : convertEpochToTime(attendance.projects[0].duration),
        '  ': attendance.projects.length === 0 ? '' : (attendance.projects[0].edited === 1 ? '*' : '') + attendance.projects[0].description,
      }
    : {
        Name: firstAttendanceOfMonthDurationSplit + getDateValue(attendance.checkIn),
        'Start Date': !isAttendance ? '-' : firstAttendanceOfMonthDurationSplit + getTimeValue(attendance.checkIn),
        'End Date': lastAttendanceOfMonthDurationSplit + getDateValue(attendance.checkOut),
        'Mandatory Working Hours Per Month': !isAttendance
          ? '-'
          : lastAttendanceOfMonthDurationSplit + getTimeValue(attendance.checkOut),
        'Total Working Time': !isAttendance ? '-' : attendance.attendanceDur,
        'Overtime Work':
          attendance.checkOut === 'N/A' || !isFirstAttendanceOfDay
            ? ''
            : getBreakTime(attendance.day, metaData)?.leaveTime,
        'Work Deduction': attendance.isHoliday ? '*' : '',
        'Total Number of Working Days': attendance.isOffDay ? '*' : '',
        'Total More Than 5 Hour Work': attendance.edited ? '*' : '',
        'Total Remaining Time Off': attendance.longEvent ? (attendance.durationSplit ? 'Duration Split' : '*') : '',
        'Total On Shift': isFirstAttendanceOfDay ? getShiftTime(attendance.day, metaData, IShiftType.ON) : '',
        'Total Off Shift': isFirstAttendanceOfDay ? getShiftTime(attendance.day, metaData, IShiftType.OFF) : '',
      };
};

export const manualColumnWidths = [
  { wpx: 90 },
  { wpx: 90 },
  { wpx: 90 },
  { wpx: 90 },
  { wpx: 120 },
  { wpx: 120 },
  { wpx: 120 },
  { wpx: 90 },
  { wpx: 120 },
  { wpx: 120 },
  { wpx: 90 },
  { wpx: 90 },
  { wpx: 90 },
];
