export const FALocale = {
  logout_btn_text: 'خروج',
  edit_profile_btn_text: 'ویرایش حساب کاربری',
  change_language_btn_text: 'زبان',
  lang_english: 'انگلیسی',
  lang_farsi: 'فارسی',
  first_punch_in: 'اولین ورود',
  last_punch_out: 'اخرین خروج',
  Today_work_time: 'کارکرد امروز',
  current_month_work_time: 'کارکرد ماه جاری',
  punch_in_btn_text: 'ورود',
  punch_in_date: 'تاریخ ورود',
  punch_out_btn_text: 'خروج',
  punch_out_date: 'تاریخ خروج',
  loading_text: 'لطفا منتظر بمانید ...',
  status: 'وضعیت',
  user_status_in: 'درحال کار',
  user_status_out: 'خروج',
  navbar_link_my_time_sheet: 'جدول زمانی من',
  navbar_link_timeOff: 'درخواست ها',
  navbar_link_leave_status: 'وضعیت مرخصی ها',
  navbar_link_companies: 'شرکت ها',
  navbar_link_employees: 'کارمندان',
  navbar_link_projects: 'پروژه ها',
  tabs_link_projects: 'فعال',
  navbar_link_reports: 'گزارشات',
  navbar_link_teams: 'تیم ها',
  navbar_link_settings: 'تنظیمات',
  edit_text: 'ویرایش',
  add_text: 'ایجاد',
  duration_text: 'مدت زمان',
  save_changes_btn_text: 'ذخیره تغییرات',
  work_duration_text: 'مدت زمان کاری',
  user_settings_text: 'ویرایش اطلاعات کاربر',
  company_settings_text: 'تنظیمات شرکت',
  name_text: 'نام',
  email_text: 'ایمیل',
  email_placeholder_text: 'ایمیل خود را وارد کنید',
  receive_email_text: 'ایمیل را دریافت نکردید؟',
  reset_password_text: 'بازنشانی رمزعبور',
  reset_password_desc: 'ایمیل خود را وارد کنید و ما دستورالعمل های بازنشانی رمزعبور را برای شما ارسال خواهیم کرد',
  strong_password_text: 'کدی که از طریق ایمیل برای شما ارسال شده است را وارد کنید.',
  reset_password_btn_text: 'بازنشانی رمزعبور',
  reset_password_request_btn: 'درخواست بازنشانی رمزعبور',
  back_to_login_btn_text: 'بازگشت به صفحه ورود',
  email_placeholder: 'لطفا ایمیل خود را وارد کنید ',
  username_placeholder: 'لطفا نام کاربری خود را وارد کنید ',
  password_text: 'رمزعبور',
  password_placeholder: 'لطفا رمز عبور خود را وارد کنید ',
  save_btn_text: 'ذخیره اطلاعات',
  annual_timeOff_text: 'مرخصی سالانه',
  used_annual_leave_text: 'مرخصی سالانه استفاده شده',
  requests_text: 'درخواست ها',
  new_request_btn_text: 'درخواست مرخصی جدید',
  edit_request: 'ویرایش درخواست مرخصی',
  send_request_btn_text: 'ارسال درخواست',
  no_request_text: 'درخواستی یافت نشد!',
  no_off_week_text: 'هیچ کس این هفته تعطیل نیست!',
  time_off_reason_text: 'دلیل مرخصی',
  leave_reason_vacation: 'تعطیلات',
  leave_reason_sick: 'مرخصی استعلاجی',
  leave_reason_academic: 'تحصیلی',
  leave_reason_other: 'غیره',
  leave_reason_placeholder_text: 'دلیل مرخصی خود را انتخاب کنید',
  leave_reason_error_text: 'لطفا دلیل مرخصی خود را انتخاب کنید!',
  timeOff_duration_text: 'مدت مرخصی',
  leave_description_text: 'توضیحات',
  leave_description_placeholder_text: 'توضیحات خود را وارد کنید',
  timeOff_type_text: 'نوع مرخصی',
  leave_type_hourly: 'ساعتی',
  leave_type_daily: 'روزانه',
  leave_from_text: 'از',
  leave_to_text: 'تا',
  leave_for_text: 'برای',
  day_text: 'روز',
  days_text: 'روز',
  team_text: 'تیم',
  cancel_text: 'لغو',
  apply_text: 'ذخیره',
  team_select_placeholder_text: 'یک تیم را انتخاب کنید',
  select_date_text: 'انتخاب تاریخ',
  yes_text: 'بله',
  close_text: 'لغو',
  cancel_request_text: 'لغو درخواست',
  cancel_request_modal_desc:
    'آیا مطمئنید که می خواهید این درخواست را لغو کنید؟ می توانید این درخواست را در برگه درخواست های لغو شده مشاهده کنید.',
  cancel_request_success: 'درخواست مرخصی شما با موفقیت لغو شد.',
  request_date_text: 'زمان درخواست',
  timeOff_Date_text: 'تاریخ مرخصی',
  requestDate_text: 'تاریخ مرخصی',
  start_text: 'شروع',
  end_text: 'پایان',
  sent_request: 'درخواست مرخصی شما با موفقیت ارسال شد.',
  edit_request_success: 'درخواست مرخصی شما با موفقیت ویرایش شد',
  table_pagination_prev: 'قبلی',
  table_pagination_next: 'بعدی',
  leave_request_tab_pending: 'در انتظار تایید',
  leave_request_tab_denied: 'تایید نشده',
  leave_request_tab_approved: 'تایید شده',
  leave_request_tab_canceled: 'لغو شده',
  Leave_request_denied: 'رد شده',
  leave_request_approve: 'تایید',
  leave_request_deny: 'رد',
  details_text: 'جزئیات',
  denied_text: 'لغو شده',
  date_text: 'تاریخ',
  add_company_btn_text: 'اضافه کردن شرکت',
  edit_company_btn_text: 'ویرایش شرکت',
  delete_company_text: 'حذف شرکت',
  delete_manager_text: 'حذف مدیر',
  company_name_placeholder_text: 'نام شرکت',
  company_name_invalid_error_text: 'نام شرکت خود را وارد کنید!',
  managers_text: 'مدیران',
  manager_text: 'مدیر',
  employee_text: 'کارمند',
  regular_text: 'کارمند',
  system_admin_text: 'مدیر سیستم',
  select_managers_text: 'مدیران را انتخاب کنید',
  address_text: 'ادرس',
  address_placeholder_text: 'آدرس خود را وارد کنید',
  no_companies: 'هیچ شرکتی وجود ندارد',
  no_managers: 'مدیری وجود ندارد',
  no_employees: 'هیچ کارمندی وجود ندارد',
  invite_manager_btn_text: 'دعوت کردن مدیر',
  username_text: 'نام کاربری',
  manager_username_placeholder_text: 'نام کاربری مدیر را وارد کنید',
  manager_username_invalid_error_text: 'نام کاربری مدیر را وارد کنید!',
  manager_email_placeholder_text: 'ایمیل مدیر را وارد کنید',
  manager_email_invalid_error_text: 'ایمیل مدیر را وارد کنید',
  manager_Fname_placeholder_text: 'نام مدیر را وارد کنید',
  manager_Lname_placeholder_text: 'نام خانوادگی مدیر را وارد کنید',
  manager_Fname_invalid_error_text: 'نام مدیر را وارد کنید',
  manager_Lname_invalid_error_text: 'نام خانوادگی مدیر را وارد کنید',
  manager_password_invalid_error_text: 'رمز عبور مدیر را وارد کنید!',
  manager_invite_with_username: 'دعوت با نام کاربری',
  manager_invite_with_email: 'دعوت با ایمیل',
  delete_company_desc_text: 'آیا مطمئنید که می خواهید این شرکت را حذف کنید؟ این عمل قابل لغو نیست.',
  delete_manager_desc_text: 'آیا مطمئن هستید که می خواهید این مدیر را حذف کنید؟ این عمل قابل لغو نیست.',
  delete_text: 'حذف',
  hour_text: 'ساعت',
  minute_text: 'دقیقه',
  minutes_text: 'دقیقه',
  add_employee_btn_text: 'اضافه کردن کارمند',
  invite_employee_btn_text: 'دعوت کردن کارمند',
  invite_success_msg_text: 'دعوتنامه با موفقیت ارسال شد!',
  role_text: 'نقش',
  employee_username_invalid_error_text: 'نام کاربری کارمند را وارد کنید!',
  employee_email_invalid_error_text: 'ایمیل کارمند را وارد کنید !',
  employee_Fname_invalid_error_text: 'نام کارمند را وارد کنید!',
  employee_Lname_invalid_error_text: 'نام خانوادگی کارمند را وارد کنید!',
  employee_password_invalid_error_text: 'رمز عبور کارمند را وارد کنید!',
  role_invalid_error_text: 'لطفا نقش را انتخاب کنید!',
  role_select_placeholder: 'نقش را انتخاب کنید',
  state_text: 'وضعیت',
  punch_in_time_text: 'زمان ورود',
  punch_out_time_text: 'زمان خروج',
  pending_requests_text: 'درخواست های در حال انتظار',
  history_text: 'تاریخچه',
  approve_btn_text: 'تایید',
  deny_btn_text: 'لغو',
  request_approved_msg_text: 'درخواست تایید شد!',
  request_denied_msg_text: 'درخواست رد شده است!',
  login_page_title: 'Login to Your  Rayka Clock  Account',
  login_page_subtitle: 'Welcome Back to Rayka Clock!',
  login_with_email: 'ورود با ایمیل',
  login_with_username: 'ورود با نام کاربری',
  invalid_email_error: 'لطفا ایمیل را بصورت صحیح وارد فرمائید !',
  invalid_username_email_error: 'لطفا نام کاربری یا ایمیل را به صورت صحیح وارد کنید.',
  login_page_email_password_error: 'لطفا ایمیل و پسورد خود را وارد کنید !',
  description_text: 'توضیحات',
  Employee_text: 'کارمند',
  calendar_setting_text: 'تنظیمات تقویم',
  calendar_type_text: 'نوع تقویم',
  weekend_text: 'تعطیلات هفتگی',
  public_holidays: 'تعطیلات رسمی',
  work_time_stting_text: 'تنطیمات ساعت کاری',
  work_time_text: 'ساعت کاری',
  start_from_text: 'شروع از',
  select_on_calendar: 'انتخاب از تقویم',
  setting_successful_update: 'تنظیمات با موفقیت بروز رسانی شد',
  All_teams_text: 'همه تیم ها',
  email_not_found: 'ایمیلی که شما وارد کردید یافت نشد!',
  emial_successfully_sent: 'ایمیل بازنشانی رمزعبور با موفقیت ارسال شد.',
  enter_emial_text: 'ایمیل خود را وارد کنید',
  new_password_text: 'رمزعبور جدید',
  enter_new_password_text: 'رمزعبور جدید خود را وارد کنید',
  confirm_new_password_text: 'تایید رمزعبور جدید',
  confirm_new_password_placeholder: 'رمزعبور جدید خود را وارد کنید',
  enter_confirm_password_text: 'رمزعبور جدید خود را مجددا وارد کنید',
  password_not_match_text: 'رمزعبور ها یکسان نیستند!',
  check_strong_password_text: 'رمزعبور باید شامل حروف بزرگ و کوچک، اعداد و علائم نگارشی است.',
  otp_error_text: 'کد otp خود را وارد کنید!',
  create_new_project_btn_text: 'ایجاد پروژه جدید',
  create_btn_text: 'ايجاد كردن',
  project_name_text: 'نام پروژه',
  enter_description_text: 'توضیحات را وارد کنید',
  select_employees_text: 'کارکنان را انتخاب کنید',
  project_name_invalid_msg_text: 'نام پروژه خود را وارد کنید',
  no_project: 'هیچ پروژه ای وجود ندارد',
  created_date_text: 'تاریخ ایجاد',
  search_text: 'جستجو',
  delete_project_text: 'حذف پروژه',
  delete_project_desc_text: 'آیا مطمئن هستید که می خواهید این پروژه را حذف کنید؟ این عمل قابل لغو نیست.',
  delete_project_success_msg: 'پروژه با موفقیت حذف شد.',
  archive_project_text: 'بایگانی پروژه',
  archive_project_desc_text: 'آیا مطمئن هستید که میخواهید این پروژه را بایگانی کنید؟ این عمل قابل لغو نیست.',
  assign_employee_text: 'اختصاص کارمند',
  assign_btn_text: 'اختصاص دهید',
  require_work_desc_text: 'نیاز به شرح کار',
  delete_employee_text: 'حذف کارمند',
  delete_employee_desc_text: 'آیا مطمئنید که می خواهید این کارمند را حذف کنید؟ این عمل قابل لغو نیست.',
  create_new_team_text: 'ایجاد تیم جدید',
  team_name_text: 'نام تیم',
  team_name_invalid_msg_text: 'نام تیم خود را وارد کنید',
  no_teams: 'تیمی وجود ندارد',
  delete_team_text: 'حذف تیم',
  delete_team_desc_text: 'آیا مطمئنید که می خواهید این تیم را حذف کنید؟ این عمل قابل لغو نیست.',
  monthly_report_text: 'گزارش ماهانه',
  projects_report_text: 'گزارش پروژه ها',
  manual_report_text: 'گزارش دستی',
  export_btn_text: 'Export',
  enter_offset_month_text: 'Enter the Offset Month',
  enter_password_text: 'رمز عبور خود را وارد کنید',
  enter_username_text: 'نام کاربری خود را وارد کنید',
  enter_name_text: 'نام خود را وارد کنید',
  forgot_password_text: 'رمز عبور را فراموش کرده اید',
  remember_me_text: 'مرا به خاطر بسپار',
  login_btn_text: 'ورود به سیستم ساعت رایکا',
  name_contain_apaces_error: 'نام نمی تواند شامل کاراکتر space باشد.',
  solar_text: 'هجری شمسی',
  hijri_text: 'هجری قمری',
  gergorian_text: 'میلادی',
  sunday_text: 'یکشنبه',
  monday_text: 'دوشنبه',
  tuesday_text: 'سه شنبه',
  wednesday_text: 'چهارشنبه',
  thursday_text: 'پنج شنبه',
  friday_text: 'جمعه',
  saturday_text: 'شنبه',
  name_contain_apaces_error_text: 'نام نمی تواند شامل کاراکتر space باشد.',
  username_contain_apaces_error_text: 'نام نمی تواند شامل کاراکتر space باشد.',
  flextime_text: 'ساعت شناوری',
  working_hours_placeholder: 'ساعت کاری را وارد کنید',
  filter_text: 'فیلتر',
  reset_filter_text: 'بازنشانی فیلتر',
  register_account_text: 'ثبت نام در حساب کاربری رایکا',
  nothing_found_text: 'هیچ چیز یافت نشد.',
  archived_text: 'بایگانی شده',
  archive_text: 'آرشیو',
  unarchive_text: 'لغو آرشیو',
  archive_project_success_msg: 'پروژه با موفقیت آرشیو شد.',
  unArchive_project_success_msg: 'لغو آرشیو پروژه با موفقیت انجام شد.',
  archive_employee_success_msg: 'کارمند با موفقیت آرشیو شد.',
  unArchive_employee_success_msg: 'لغو آرشیو کارمند با موفقیت انجام شد.',
  select_a_date_error: 'لطفا تاریخ مورد نظر را مشخص کنید.',
  select_time_error: 'لطفا زمان مورد نظر رو مشخص کنید.',
  navbar_link_setting: 'تنظیمات',
  navbar_link_profile: 'پروفایل',
  navbar_link_shifts: 'شیفت ها',
  navbar_link_leave_setting: 'تنظیمات مرخصی',
  navbar_link_company: 'شرکت',
  shifts_text: 'شیفت',
  active_text: 'فعال',
  reload_text: 'بارگیری دوباره',
  shift_settings_text: 'تنظیمات شیفت',
  add_shift_text: 'افزودن شیفت',
  first_name_text: 'نام',
  last_name_text: 'نام خانوادگی',
  time_duration_error: 'زمان پایان نباید زودتر از زمان شروع باشد.',
  enter_first_name: 'لطفا نام خود را وارد کنید!',
  enter_last_name: 'لطفا نام خانوادگی خود را وارد کنید'!,
  confirm_password_text: 'تائید رمزعبور',
  sort_by_text: 'مرتب سازی بر اساس',
  lastName_sort_descending: 'مرتب سازی بر اساس الف - ی',
  lastName_sort_ascending: 'مرتب سازی بر اساس ی - الف',
  employee_settings_text: 'تنظیمات کارمند',
  edit_employee_success_msg: 'کارمند با موفقیت ویرایش شد.',
  username_or_email_text: 'نام کاربری یا ایمیل',
  annual_time_off_days_text: 'روزهای مرخصی سالانه',
  start_date_text: 'تاریخ شروع',
  complete_profile_text: 'کامل کردن پروفایل',
  set_time_off_status: 'تنظیم وضعیت مرخصی',
  success_time_off_status: 'وضعیت مرخصی با موفقیت اضافه شد.',
  time_off_request_text: 'درخواست مرخصی',
  approve_text: 'تائید',
  deny_text: 'رد',
  comment_text: 'توضیحات',
  enter_comment_text: 'توضیحات را وارد کنید...',
  and_text: 'و',
  reviewer_text: 'بررسی کننده',
  previous_time_off_request_date: 'تاریخ درخواست قبلی مرخصی',
  new_time_off_request_date: 'تاریخ درخواست جدید مرخصی',
  previous_description: 'توضیحات قبلی',
  new_description: 'توضیحات جدید',
  ok_text: 'تایید',
  Employees_can_add_their_attendance_until: 'کارمندان می توانند زمان ورود و خروج خود را تا زمان مشخص شده اضافه کنند.',
  Employees_can_edit_their_attendance_until: 'کارمندان می توانند زمان ورود و خروج خود را تا زمان مشخص شده ویرایش کنند.',
  Off_shift_treshold_limit: 'محدویت زمان خارج از شیفت',
  the_company_setting_has_been_updated: 'تنظیمات شرکت با موفقیت به روز شد.',
  no_employee_selected: 'کارمندی انتخاب نشد. لطفا انتخاب کنید.',
  total_Duration_text: 'مدت زمان کل',
};
